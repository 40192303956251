export enum FeatureFlags {
    OnboardingFlow = 'newOnboardingFlow',
    HideDailyCheckIn = 'journeysHideDailyCheckIn',
    HasAcuteCareAccess = 'hasAcuteCareAccess',
    isNewDailyExperienceEnabled = 'isNewDailyExperienceEnabled',
    HasIntentionSetting = 'HasIntentionSetting',
    hasResetPostWatchScreen = 'isResetFeedbackEnabled',
    IsNewCompanyResourceEnabled = 'isNewCompanyResourceCardEnabled',
    IsMicrostepCardV2Enabled = 'isMicrostepCardV2Enabled',
    HasBaselineAssessmentEnabled = 'hasBaselineAssessmentEnabled',
    IsIntentionSettingTiersEnabled = 'IsIntentionSettingTiersEnabled'
}
